import React, { useEffect, useState, useRef } from "react";
import NoteEditor from "./NoteEditor";
import Note from "../models/noteModel";
import { generateReview2024Question, getReview2024ByUserId } from "../services/review2024Service";
import { useNavigate } from "react-router-dom";
import { generateSummaryReview2024, createReview2024ForUser } from "../services/review2024Service";
import { analyzeAllEmotionsHume } from "../services/emotionAnalysisService";
import Review2024Model from "../models/review2024Model";
import "../styles/Review2024.css";
import { createNoteForUserDb } from "../services/notesService";
import { generateLookingForwardsReview2024 } from "../services/review2024Service";

import My2024 from "./My2024";

function Review2024({ user }) {
  const [note, setNote] = useState(() => {
    // Initialize a new note with default values
    return new Note(
      Date.now(), // id
      "2024 Year in Review", // title
      "<reflection class='reflection-node'>In a single sentence, how would you describe the last 12 months?</reflection><p></p>", // content
      "local", // userId
      new Date().toISOString(), // lastEdited
      [], // emotions
      false // hidden
    );
  });
  const navigate = useNavigate();

  const [numQuestions, setNumQuestions] = useState(1);
  const [isGeneratingReflection, setIsGeneratingReflection] = useState(false);
  const [review2024, setReview2024] = useState(null);
  const tiptapEditorRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedNote = localStorage.getItem("review2024");
    if (savedNote) {
      const parsedNote = JSON.parse(savedNote);
      const reflectionCount = (
        parsedNote.content.match(/<reflection class="reflection-node">/g) || []
      ).length;
      //   console.log(reflectionCount);
      setNumQuestions(reflectionCount);
      setNote(
        new Note(
          parsedNote.id,
          parsedNote.title,
          parsedNote.content,
          parsedNote.userId,
          parsedNote.lastEdited,
          parsedNote.emotions,
          parsedNote.hidden
        )
      );
    }
  }, []);

  const handleUpdateNote = (currentNote, updatedFields, updateLastEdited = true) => {
    const updatedNote = currentNote.update(updatedFields, updateLastEdited);
    setNote(updatedNote);
    // Save to localStorage
    localStorage.setItem("review2024", JSON.stringify(updatedNote));
    return updatedNote;
  };

  // Mock function for reflection generation
  const generateQuestion = async () => {
    setIsGeneratingReflection(true);
    // This is where you could add reflection generation logic
    const question = await generateReview2024Question(note.content, numQuestions);
    setNumQuestions(numQuestions + 1);
    if (tiptapEditorRef.current) {
      await tiptapEditorRef.current.insertReflection(question);
    }
    setIsGeneratingReflection(false);
  };

  const handleCompleteReflection = async () => {
    if (!user) {
      // Store the intended destination before redirecting
      localStorage.setItem("authRedirect", "/review2024");
      navigate("/auth");
      return;
    }

    setIsGeneratingReflection(true);
    const response = await generateSummaryReview2024(note, user.id);
    if (response == null) {
      setIsGeneratingReflection(false);
      return;
    }
    const { summary, themes } = response;
    const reviewId = crypto.randomUUID();
    // setReview2024(summary);

    const emotions = await analyzeAllEmotionsHume(note.content, user.uid, note.id);

    const emotionBreakdown = {};
    let totalEmotions = 0;
    emotions.slice(0, 3).forEach((emotion) => {
      emotionBreakdown[emotion.name] = emotion.score;
      totalEmotions += emotion.score;
    });
    Object.keys(emotionBreakdown).forEach((emotion) => {
      emotionBreakdown[emotion] = emotionBreakdown[emotion] / totalEmotions;
    });

    const lookingforwardsResponse = await generateLookingForwardsReview2024(note, user.uid);

    const review = new Review2024Model(
      reviewId,
      emotionBreakdown,
      summary,
      themes,
      lookingforwardsResponse
    );
    setReview2024(review);
    await createReview2024ForUser(review, user.uid);
    await createNoteForUserDb(note, user.uid);
    navigate(`/my2024`);
    setIsGeneratingReflection(false);
  };

  return (
    <div className="review2024-container">
      <NoteEditor
        note={note}
        ref={tiptapEditorRef}
        updateNote={handleUpdateNote}
        generateReflection={generateQuestion}
        isGeneratingReflection={isGeneratingReflection}
        is2024Review={true}
        numQuestions={numQuestions}
        handleComplete2024Review={handleCompleteReflection}
      />
    </div>
  );
}

export default Review2024;
