import React, { forwardRef, useState, useEffect } from "react";
import TiptapEditor from "./TiptapEditor";
import { Heart, Sparkle } from "lucide-react";
import useIsMobile from "../utils/useIsMobile";
const NoteEditor = forwardRef(
  (
    {
      note,
      updateNote,
      generateReflection,
      isGeneratingReflection,
      is2024Review = false,
      numQuestions = null,
      handleComplete2024Review = null,
    },
    ref
  ) => {
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const isMobile = useIsMobile();
    useEffect(() => {
      // Check if we're on mobile
      if (typeof window.visualViewport !== "undefined") {
        const handleResize = () => {
          // If viewport height is significantly reduced, keyboard is likely visible
          setIsKeyboardVisible(window.visualViewport.height < window.innerHeight * 0.75);
        };

        window.visualViewport.addEventListener("resize", handleResize);
        return () => window.visualViewport.removeEventListener("resize", handleResize);
      }
    }, []);

    if (!note) return null;

    return (
      <div className="note-editor-container">
        <TiptapEditor
          ref={ref}
          note={note}
          updateNote={updateNote}
          generateReflection={generateReflection}
          is2024Review={is2024Review}
          numQuestions={numQuestions}
          isGeneratingReflection={isGeneratingReflection}
          handleComplete2024Review={handleComplete2024Review}
        />
        {isMobile && !is2024Review && (
          <button
            onClick={generateReflection}
            className="sidebar-button reflect-button"
            disabled={isGeneratingReflection}
            style={{
              position: "fixed",
              right: "20px",
              bottom: isKeyboardVisible ? "50%" : "20px",
              transform: isKeyboardVisible ? "translateY(50%)" : "none",
              zIndex: 1000,
              transition: "all 0.3s ease",
            }}
          >
            {isGeneratingReflection ? (
              <span>
                <Heart
                  className="mb-[3px] animate-[pulse_1.25s_cubic-bezier(0.4,0,0.6,1)_infinite]"
                  size={12}
                />
              </span>
            ) : (
              <Sparkle className="sparkle-sidebar-icon" />
            )}
            Reflect
          </button>
        )}
      </div>
    );
  }
);

// Add display name for debugging
NoteEditor.displayName = "NoteEditor";

export default NoteEditor;
