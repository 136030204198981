import React, { useState } from "react";
import { X } from "lucide-react";
import axios from "axios";
import "../styles/Modal.css";
import { getAuth } from "firebase/auth";

const FeedbackModal = ({ isOpen, onClose, user }) => {
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    if (!feedback.trim()) return;

    setIsSubmitting(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/feedback`,
        { userId: user.uid, content: feedback.trim() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setMessage("Thank you for your feedback! ❤️");
        setFeedback("");
        setTimeout(() => {
          onClose();
          setMessage("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setMessage("Failed to submit feedback. Please try again.");
    }
    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Send feedback</h3>
          <button onClick={onClose} className="icon-button">
            <X size={16} />
          </button>
        </div>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Tell us what you think..."
          className="feedback-textarea"
          maxLength={1000}
        />
        {message && <div className="feedback-message">{message}</div>}
        <button
          onClick={handleSubmit}
          className="submit-button"
          disabled={isSubmitting || !feedback.trim()}
        >
          {isSubmitting ? "Sending..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;
