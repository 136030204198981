// frontend/src/components/ProfileModal.js
import React, { useState, useEffect } from "react";
import { Pencil } from "lucide-react";
import "../styles/Modal.css";

export default function ProfileModal({ isOpen, user, goal, setGoal, onClose, handleSignOut }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedGoal, setEditedGoal] = useState(goal);

  useEffect(() => {
    setEditedGoal(goal);
    // Adjust heights of all textareas after goal changes
    const textareas = document.querySelectorAll(".profile-textarea");
    textareas.forEach((textarea) => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    });
  }, [goal, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="goal-modal-header">
          <h3>My Account</h3>
        </div>

        <div>
          <div>
            <div className="profile-section">
              <span className="profile-section-heading">Email</span>
              <span className="text-sm">{user?.email}</span>
            </div>
            <div className="profile-section">
              <div className="flex items-center gap-2">
                <span className="profile-section-heading">My introduction</span>
                {!isEditing && (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="text-[#3838688c] hover:text-[#322E33]"
                  >
                    <Pencil size={14} />
                  </button>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <textarea
                  type="text"
                  value={isEditing ? editedGoal[0] : goal[0] || ""}
                  onChange={(e) => {
                    if (isEditing) {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                      setEditedGoal([
                        ...editedGoal.slice(0, 0),
                        e.target.value,
                        ...editedGoal.slice(1),
                      ]);
                    }
                  }}
                  readOnly={!isEditing}
                  className="profile-textarea !p-0"
                  placeholder="What does a typical day look like for you?"
                  style={{ minHeight: "72px" }}
                />
                <div className="profile-dashed-divider" />
                <textarea
                  type="text"
                  value={isEditing ? editedGoal[1] : goal[1] || ""}
                  onChange={(e) => {
                    if (isEditing) {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                      setEditedGoal([
                        ...editedGoal.slice(0, 1),
                        e.target.value,
                        ...editedGoal.slice(2),
                      ]);
                    }
                  }}
                  readOnly={!isEditing}
                  className={`profile-textarea !p-0 ${!isEditing ? "min-h-0" : ""}`}
                  placeholder="How would you describe yourself?"
                  style={{ minHeight: "72px" }}
                />
                <div className="profile-dashed-divider" />
                <textarea
                  type="text"
                  value={isEditing ? editedGoal[2] : goal[2] || ""}
                  onChange={(e) => {
                    if (isEditing) {
                      e.target.style.height = "auto";
                      e.target.style.height = e.target.scrollHeight + "px";
                      setEditedGoal([
                        ...editedGoal.slice(0, 2),
                        e.target.value,
                        ...editedGoal.slice(3),
                      ]);
                    }
                  }}
                  readOnly={!isEditing}
                  className={`profile-textarea !p-0 ${!isEditing ? "min-h-0" : ""}`}
                  placeholder="What would you like to achieve through journaling?"
                  style={{ minHeight: "72px" }}
                />
                <div className="flex justify-end gap-2">
                  {isEditing && (
                    <>
                      <button
                        onClick={() => {
                          setIsEditing(false);
                          setEditedGoal(goal);
                        }}
                        className="small-button"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          setGoal(editedGoal);
                          setIsEditing(false);
                        }}
                        className="small-button"
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="privacy-section">
              <span className="profile-section-heading">Privacy & Data</span>
              <p className="text-sm text-[#322E33]">
                To delete your account, please{" "}
                <a href="mailto:emily49@stanford.edu" className="text-[#6372ad] hover:underline">
                  contact us.
                </a>{" "}
                Your notes are deleted permanently when you delete them.
              </p>
              <p className="text-sm text-[#322E33]">
                Read our{" "}
                <a
                  href="https://knowing-radium-977.notion.site/terms-and-privacy?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-[#6372ad] hover:underline"
                >
                  Privacy Policy & Terms of Service
                </a>{" "}
                here.
              </p>
            </div>
          </div>

          <div className="goal-actions-no-padding">
            <button onClick={onClose} className="skip-button">
              Close
            </button>
            <button onClick={handleSignOut} className="submit-button">
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
