import axios from "axios";
import { getAuth } from "firebase/auth";
const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://journal-server-two.vercel.app/user"
    : "http://localhost:3001/user";

export const getUserGoal = async (userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${API_URL}/get-goal/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data.userGoal;
  } catch (error) {
    console.error("Error fetching user goal:", error);
    return null;
  }
};

export const setUserGoal = async (userId, goal) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    await axios.post(
      `${API_URL}/set-goal/${userId}`,
      { goal },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Error setting user goal:", error);
  }
};
