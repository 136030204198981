import axios from "axios";
import { cleanWithNewLines, removeHTMLTagsAndReflections } from "../utils/parsing";
import { getAuth } from "firebase/auth";

const QUESTION_API_URL =
  process.env.NODE_ENV === "production"
    ? "https://journal-server-two.vercel.app/review2024question"
    : "http://localhost:3001/review2024question";

const REVIEW_API_URL =
  process.env.NODE_ENV === "production"
    ? "https://journal-server-two.vercel.app/review2024"
    : "http://localhost:3001/review2024";

export const QUESTIONS = [
  "In a single sentence, how would you describe your 2024?",
  "What relationships and practices brought you joy, growth, or peace?",
  "What are you ready to release from this year? Consider patterns, thoughts, or habits that no longer serve who you're becoming.",
  "Looking ahead, what would you like to welcome into your life? What do you hope to cultivate that was missing this year?",
  "What moments or new experiences from this year will stay with you, and why?",
  "Which achievement makes you most proud, and why?",
  "What challenges shaped you, and what did they teach you?",
  "What dream or goal came into sharper focus?",
];

export const generateReview2024Question = async (content, questionNumber) => {
  try {
    const cleanedText = removeHTMLTagsAndReflections(content);

    const response = await axios.post(QUESTION_API_URL, {
      content: cleanedText,
      questionNumber,
    });
    return response.data.question;
  } catch (error) {
    console.error("Error generating review 2024 question:", error);
    return QUESTIONS[questionNumber];
  }
};

export const createReview2024ForUser = async (review, userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const body = { review: review.toDatabaseFormat() };

    const response = await axios.post(`${REVIEW_API_URL}/create/${userId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating review 2024:", error);
  }
};

export const getReview2024ByUserId = async (userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${REVIEW_API_URL}/get/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching review 2024:", error);
    return null;
  }
};

const validateResponse = (response) => {
  return response && response.summary && response.themes;
};

export const generateSummaryReview2024 = async (note, userId) => {
  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      const response = await axios.post(
        `${REVIEW_API_URL}/summary`,
        {
          note: cleanWithNewLines(note.content),
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (validateResponse(response.data)) {
        return response.data;
      }

      throw new Error("Invalid response format");
    } catch (error) {
      attempts++;
      console.error(`Attempt ${attempts} failed:`, error);

      if (attempts === maxAttempts) {
        console.error("Max attempts reached for generating summary review 2024");
        return null;
      }

      // Wait for 1 second before retrying
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  return null;
};

const validateLookingForwardsResponse = (response) => {
  return response && response.keep && response.leave && response.start;
};

export const generateLookingForwardsReview2024 = async (note, userId) => {
  let attempts = 0;
  const maxAttempts = 3;

  while (attempts < maxAttempts) {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.post(
        `${REVIEW_API_URL}/lookingforwards`,
        { note: cleanWithNewLines(note.content), userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (validateLookingForwardsResponse(response.data)) {
        return response.data;
      }

      throw new Error("Invalid response format");
    } catch (error) {
      attempts++;
      console.error(`Attempt ${attempts} failed:`, error);

      if (attempts === maxAttempts) {
        console.error("Max attempts reached for generating looking forwards review 2024");
        return null;
      }

      // Wait for 100ms before retrying
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  return null;
};
