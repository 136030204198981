import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "../styles/SignIn.css";
import "../styles/buttons.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { BadgeAlert, BadgeCheck } from "lucide-react";
import { trackUserAuth, trackEvent } from "../services/trackUserAuthService";

function SignIn({ setUser }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isOver13, setIsOver13] = useState(false);

  const cleanFirebaseError = (errorMessage) => {
    return errorMessage.replace("Firebase: ", "").replace(/\s*\([^)]*\)\.?/, "");
  };

  const handleSignUp = () => {
    if (!termsAccepted) {
      setError("Please accept the Terms and Privacy Policy to continue");
      return;
    }
    if (!isOver13) {
      setError("You must be 13 or older to use Pearl");
      return;
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        setError("");
        trackUserAuth(userCredential.user, "Sign Up");
        const redirectPath = localStorage.getItem("authRedirect");
        // Clear the stored path

        // Redirect to the stored path or default to home
        navigate(redirectPath || "/");
        localStorage.removeItem("authRedirect");
      })
      .catch((error) => {
        console.log(error);
        setError(cleanFirebaseError(error.message));
      });
  };

  const handleSignIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        setError("");
        setMessage("");
        trackUserAuth(userCredential.user, "Sign In");

        const redirectPath = localStorage.getItem("authRedirect");
        // Clear the stored path

        // Redirect to the stored path or default to home
        navigate(redirectPath || "/");
        localStorage.removeItem("authRedirect");
      })
      .catch((error) => {
        setError(cleanFirebaseError(error.message));
        console.log(error);
        trackEvent("Sign In Error", { error: error.message, email: email });
      });
  };

  const handleGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((userCredential) => {
        setUser(userCredential.user);
        setError("");
        setMessage("");
        trackUserAuth(userCredential.user, "Google Sign In");

        const redirectPath = localStorage.getItem("authRedirect");
        console.log(redirectPath);
        // Clear the stored path

        // Redirect to the stored path or default to home
        navigate(redirectPath || "/");
        localStorage.removeItem("authRedirect");
      })
      .catch((error) => {
        setError(cleanFirebaseError(error.message));
        console.log(error);
        trackEvent("Google Sign In Error", { error: error.message });
      });
  };

  return (
    <div className="sign-in-container">
      <h2 className="sign-in-header">{isSignUp ? "Sign Up" : "Sign In"}</h2>
      <div className="sign-in-items">
        {error && (
          <div className="alert-container">
            <BadgeAlert size={16} />
            <span className="alert-text">{error}</span>
          </div>
        )}
        {message && (
          <div className="message-container">
            <BadgeCheck size={16} />
            <span className="alert-text">{message}</span>
          </div>
        )}
        <div className="input-container">
          <span className="input-label">Email</span>
          <input
            type="email"
            placeholder="jinx@exampleemail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input-field-container"
          />
        </div>
        <div className="input-container">
          <span className="input-label">Password</span>
          <input
            type="password"
            placeholder="supersecretpassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-field-container"
          />
        </div>

        <button
          onClick={isSignUp ? handleSignUp : handleSignIn}
          className="signin-button"
          disabled={!email || !password}
        >
          {isSignUp ? "Sign Up" : "Sign In"}
        </button>
        <button onClick={handleGoogleSignIn} className="google-button">
          <FontAwesomeIcon icon={faGoogle} className="google-icon" />
          {isSignUp ? "Sign up with Google" : "Sign in with Google"}
        </button>
      </div>

      <div className="small-text-container">
        <button
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError("");
            setMessage("");
            setTermsAccepted(false);
            setIsOver13(false);
          }}
          className="small-text"
        >
          {isSignUp ? "Already have an account?" : "Need an account?"}
        </button>

        {!isSignUp && (
          <button onClick={() => navigate("/forgot-password")} className="small-text">
            Forgot password?
          </button>
        )}

        {isSignUp && (
          <div className="small-text-container">
            <div className="terms-container">
              <input
                type="checkbox"
                id="age"
                checked={isOver13}
                onChange={(e) => setIsOver13(e.target.checked)}
              />
              <label htmlFor="age" className="small-text-static">
                I confirm that I am 13 years of age or older
              </label>
            </div>

            <div className="terms-container">
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label htmlFor="terms" className="small-text-static">
                I have read and agree to the{" "}
                <a
                  href="https://knowing-radium-977.notion.site/terms-and-privacy?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small-text"
                >
                  Terms of Service and Privacy Policy
                </a>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignIn;
