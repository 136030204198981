class Review2024Model {
  constructor(
    id,
    emotionBreakdown,
    summary,
    themes,
    lookingForwards,
    challenges,
    learnings,
    gratitude
  ) {
    this.id = id;
    this.emotionBreakdown = emotionBreakdown;
    this.summary = summary;
    this.themes = themes;
    this.lookingForwards = lookingForwards;
    this.challenges = challenges;
    this.learnings = learnings;
    this.gratitude = gratitude;
  }

  toDatabaseFormat() {
    return {
      id: this.id,
      emotionBreakdown: this.emotionBreakdown,
      summary: this.summary,
      themes: this.themes,
      lookingForwards: this.lookingForwards,
      challenges: this.challenges,
      learnings: this.learnings,
      gratitude: this.gratitude,
    };
  }
}

export default Review2024Model;
