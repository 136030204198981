import React, { useState, useEffect, useRef } from "react";
import "../styles/NoteList.css";
import "../styles/NoteEditor.css";
import useIsMobile from "../utils/useIsMobile";
import { ChevronsLeft, Heart, Search, Trash2, Eye, EyeOff, ChevronsRight, X } from "lucide-react"; // Replace the old icon imports
import { EMOTION_COLORS } from "../constants/emotionColors";
import { updateNoteDb } from "../services/notesService";
import { cleanWithNewLines } from "../utils/parsing";

function NoteList({
  notes = [],
  selectedNote,
  setSelectedNoteId,
  selectedNoteId,
  deleteSelectedNote,
  updateNote,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [lastSelectedNoteId, setLastSelectedNoteId] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useIsMobile();
  const [swipedNoteId, setSwipedNoteId] = useState(null);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  // useEffect(() => {
  //   if (notes.length > 0 && !selectedNote) {
  //     setSelectedNoteId(notes[0].id);
  //   }
  // }, [notes, selectedNote, setSelectedNoteId]);

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day] = formattedDate.split(" ");
    return `${month.toUpperCase()} ${day}`;
  };

  const handleSearchClick = () => {
    setIsSearching(true);
    setTimeout(() => {
      const searchInput = document.querySelector(".search-input");
      if (searchInput) {
        searchInput.focus();
      }
    }, 0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCancelSearch = () => {
    setIsSearching(false);
    setSearchQuery("");
  };

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const filteredNotes = (
    searchQuery !== ""
      ? notes.filter(
          (note) =>
            note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            note.content.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : notes
  ).sort((a, b) => new Date(b.lastEdited) - new Date(a.lastEdited));

  const toggleNoteVisibility = async (note, e) => {
    e.stopPropagation();
    const updatedNote = updateNote(note, { hidden: !note.hidden }, false);
    await updateNoteDb(note.id, updatedNote, note.userId);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    touchEndX.current = null;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
    const noteItem = e.currentTarget;
    const swipeDistance = touchStartX.current - touchEndX.current;

    if (swipeDistance > 0) {
      noteItem.style.transform = `translateX(-${Math.min(swipeDistance, 100)}px)`;
      noteItem.style.transition = "transform 0.1s";
    }
  };

  const handleTouchEnd = (note, e) => {
    // Only proceed if we have both start and end touch positions
    if (!touchStartX.current || !touchEndX.current) {
      return;
    }

    const swipeDistance = touchStartX.current - touchEndX.current;
    const noteItem = e.currentTarget;

    if (swipeDistance > 100) {
      // Delete threshold reached
      noteItem.style.transform = "translateX(-100%)";
      noteItem.style.transition = "transform 0.2s";
      setTimeout(() => deleteSelectedNote(note.id), 200);
    } else {
      // Reset position
      noteItem.style.transform = "translateX(0)";
      noteItem.style.transition = "transform 0.2s";
    }

    // Reset touch positions
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <>
      <div className={`note-list-container ${isCollapsed ? "collapsed" : ""}`}>
        <div className="note-list-header">
          {isSearching ? (
            <div className="search-input-container">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
                placeholder="Search notes..."
              />
              <button onClick={handleCancelSearch} className="icon-button">
                <X size={16} className="text-[#322e33]" />
              </button>
            </div>
          ) : (
            <>
              <h2 className="note-list-title">My notes</h2>
              <div className="icon-button-group">
                <button onClick={handleSearchClick} className="icon-button">
                  <Search size={16} />
                  <span className="tooltip tooltip-bottom-left">Search</span>
                </button>
                {!isMobile && (
                  <button onClick={handleCollapseClick} className="icon-button">
                    <ChevronsLeft size={16} />
                    <span className="tooltip tooltip-bottom-left">Collapse</span>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        <div className="note-list">
          <ul className="note-list">
            <div className="note-list-spacer"></div>
            {filteredNotes.map((note) => {
              const title = note.title || "New note";
              const previewText = note.content
                ? cleanWithNewLines(note.content)
                : "No additional text...";
              return (
                <div key={note.id} style={{ position: "relative" }}>
                  <div className="delete-indicator">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Trash2 size={24} color="white" />
                    </div>
                  </div>
                  <li
                    className={`note-list-item ${
                      selectedNote && selectedNote.id === note.id ? "selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedNoteId(note.id);
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={(e) => handleTouchMove(e)}
                    onTouchEnd={(e) => handleTouchEnd(note, e)}
                  >
                    <div>
                      <div className="note-header">
                        <span className={`note-title ${note.hidden ? "blurred" : ""}`}>
                          {title}
                        </span>
                        {!isMobile && (
                          <button
                            className="icon-button-small visibility-toggle"
                            onClick={(e) => toggleNoteVisibility(note, e)}
                          >
                            {note.hidden ? <EyeOff size={16} /> : <Eye size={16} />}
                          </button>
                        )}
                      </div>
                      <div className={`note-preview ${note.hidden && !isMobile ? "blurred" : ""}`}>
                        {previewText}
                      </div>
                      <div className="note-metadata-container">
                        {note.emotions && note.emotions.length > 0 && (
                          <div className="note-emotions">
                            {(() => {
                              return note.emotions
                                .sort((a, b) => b.score - a.score)
                                .map((emotion, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="note-editor-emotion"
                                      style={{
                                        color: EMOTION_COLORS[emotion]?.text || "#000000",
                                        backgroundColor:
                                          EMOTION_COLORS[emotion]?.background || "#FFFFFF",
                                        minWidth: index === 0 ? "auto" : "18px",
                                        width: index === 0 ? "auto" : "18px",
                                        height: "18px",
                                        padding:
                                          index === 0 ? "0.32rem 0.65rem 0.125rem 0.65rem" : "6px",
                                        borderRadius: "9999px",
                                      }}
                                    >
                                      {index === 0 ? emotion : ""}
                                    </span>
                                  );
                                });
                            })()}
                          </div>
                        )}
                        <p className="note-date">{formatDate(note.lastEdited)}</p>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
            <div className="note-list-bottom-spacer"></div>
          </ul>
        </div>
      </div>
      {isCollapsed && (
        <div className="expand-button-container">
          <button onClick={handleCollapseClick} className="icon-button">
            <ChevronsRight size={16} className="text-[#3838688C]" />
            <span className="tooltip tooltip-bottom-right">Expand</span>
          </button>
        </div>
      )}
    </>
  );
}

export default NoteList;
