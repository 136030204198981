import axios from "axios";
import { getAuth } from "firebase/auth";
const API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/notes`
    : "http://localhost:3001/notes";

export const createNoteForUserDb = async (note, userId) => {
  try {
    const body = { note: note.toDatabaseFormat(), noteId: note.id };
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(`${API_URL}/create/${userId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error creating note:", error);
  }
};

export const getNoteByIdDb = async (id, userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${API_URL}/get/${userId}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching note:", error);
  }
};

export const updateNoteDb = async (id, note, userId) => {
  try {
    if (userId === "local") {
      return;
    }
    const body = { note: note.toDatabaseFormat() };
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(`${API_URL}/update/${userId}/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error updating note:", error);
  }
};

export const deleteNoteDb = async (id, userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(`${API_URL}/delete/${userId}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error deleting note:", error);
  }
};

export const getNotesByUserIdDb = async (userId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(`${API_URL}/get/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching notes:", error);
  }
};
