import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { getReview2024ByUserId } from "../services/review2024Service";
import { EMOTION_COLORS } from "../constants/emotionColors";
import { useNavigate } from "react-router-dom";
import "../styles/Review2024.css";
import useIsMobile from "../utils/useIsMobile";
function My2024({ user }) {
  const [review2024, setReview2024] = useState(null);
  const navigate = useNavigate();

  const isMobile = useIsMobile();
  useEffect(() => {
    const fetchReview2024 = async () => {
      try {
        const data = await getReview2024ByUserId(user?.uid);

        setReview2024(data.review);
        console.log(data.review);
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
    };

    if (user?.uid) {
      fetchReview2024();
    }
  }, [user]);

  const addOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return day + "th";
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  const getTopEmotions = (emotionBreakdown, count = 3) => {
    return Object.entries(emotionBreakdown)
      .sort(([, a], [, b]) => b - a)
      .slice(0, count)
      .map(([emotion, ratio]) => ({
        emotion,
        percentage: Math.round(ratio * 100),
      }));
  };

  const handleExport = async () => {
    const element = document.querySelector(".review2024-export");
    if (!element) return;

    try {
      // Create a wrapper div with padding
      const wrapper = document.createElement("div");
      wrapper.style.paddingLeft = "20px";
      wrapper.style.paddingRight = "20px";
      wrapper.style.paddingBottom = "20px";
      wrapper.style.backgroundColor = "#FCF9FA";

      // Clone the element and append to wrapper
      const clone = element.cloneNode(true);
      wrapper.appendChild(clone);
      document.body.appendChild(wrapper);

      let targetWidth;
      let targetHeight;
      if (isMobile) {
        // Set fixed dimensions with 16:9 ratio
        targetWidth = 375; // Base width
        const aspectRatio = 9 / 16;
        targetHeight = targetWidth / aspectRatio;
      } else {
        // Set fixed dimensions with 16:9 ratio
        targetWidth = 700; // Base width
        const aspectRatio = 1 / 1;
        targetHeight = targetWidth / aspectRatio;
      }

      // Force the wrapper to maintain these dimensions
      wrapper.style.width = `${targetWidth}px`;
      wrapper.style.height = `${targetHeight}px`;
      wrapper.style.overflow = "hidden";
      wrapper.style.display = "flex";
      wrapper.style.alignItems = "top";
      wrapper.style.justifyContent = "center";

      const canvas = await html2canvas(wrapper, {
        backgroundColor: "#FCF9FA",
        scale: 2, // Higher quality
        width: targetWidth,
        height: targetHeight,
        logging: false,
        useCORS: true,
        allowTaint: true,
      });

      // Clean up the temporary wrapper
      document.body.removeChild(wrapper);

      const link = document.createElement("a");
      link.download = "2024-year-in-review.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    } catch (error) {
      console.error("Error exporting review:", error);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");

    return `${month} ${addOrdinalSuffix(day)} ${year} at ${hours}:${minutes}`;
  };

  return (
    <>
      {review2024 ? (
        <div className="review2024-wrapper">
          <div className="review2024-export">
            <div className="review2024-header">
              <p className="review2024-date">{formatDate(new Date())}</p>
            </div>
            <h2 className="review2024-title">2024 Year in Review</h2>

            <div
              className="emotion-summary-section"
              style={{
                background: `linear-gradient(180deg, #fdfafb3d 10%, #fdfafb9f 30%, #fdfafbb4 100%),linear-gradient(90deg, 
                ${
                  EMOTION_COLORS[
                    Object.entries(review2024.emotionBreakdown).sort(
                      ([, a], [, b]) => b - a
                    )[0]?.[0]
                  ]?.background || "#04c4ff3a"
                } 30%, 
                ${
                  EMOTION_COLORS[
                    Object.entries(review2024.emotionBreakdown).sort(
                      ([, a], [, b]) => b - a
                    )[1]?.[0]
                  ]?.background || "#0800f921"
                } 60%, 
                ${
                  EMOTION_COLORS[
                    Object.entries(review2024.emotionBreakdown).sort(
                      ([, a], [, b]) => b - a
                    )[2]?.[0]
                  ]?.background || "#ffc40053"
                } 100%
                )`,
              }}
            >
              <div className="emotion-summary-grid">
                {review2024.emotionBreakdown &&
                  getTopEmotions(review2024.emotionBreakdown).map(({ emotion, percentage }) => (
                    <div
                      key={emotion}
                      className={`emotion-summary-container ${emotion.toLowerCase()}`}
                      style={{
                        color: EMOTION_COLORS[emotion]?.text || "#322E33",
                      }}
                    >
                      <p className="year-emotion-summary-number">{percentage}%</p>
                      <p className="emotion-summary-emotion">{emotion.toLowerCase()}</p>
                    </div>
                  ))}
              </div>
              <div className="week-description">
                <p>{review2024.summary}</p>
              </div>
              <div className="descriptive-words">
                {review2024.themes.map((theme, index) => (
                  <React.Fragment key={index}>
                    <p className="descriptive-word">{theme.toLowerCase()}</p>
                    {index < review2024.themes.length - 1 && <p className="descriptive-star">✦</p>}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="looking-forwards-section">
            <div className="section-divider">
              <hr />
              <span>looking forwards</span>
              <hr />
            </div>
            <div className="looking-forwards-grid">
              <div className="looking-forwards-item">
                <h3 className="looking-forwards-title">KEEP</h3>
                {review2024.lookingForwards.keep.map((line, index) => (
                  <p className="looking-forwards-line" key={index}>
                    • {line}
                  </p>
                ))}
              </div>
              <div className="vertical-divider"></div>
              <div className="looking-forwards-item">
                <h3 className="looking-forwards-title">LEAVE</h3>
                {review2024.lookingForwards.leave.map((line, index) => (
                  <p className="looking-forwards-line" key={index}>
                    • {line}
                  </p>
                ))}
              </div>
              <div className="vertical-divider"></div>
              <div className="looking-forwards-item">
                <h3 className="looking-forwards-title">START</h3>
                {review2024.lookingForwards.start.map((line, index) => (
                  <p className="looking-forwards-line" key={index}>
                    • {line}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center gap-4 mt-4">
            <button
              onClick={handleExport}
              className="export-button"
              // style={{
              //   margin: "20px auto",
              //   display: "block",
              //   padding: "10px 20px",
              //   borderRadius: "8px",
              //   border: "none",
              //   backgroundColor: "#007AFF",
              //   color: "white",
              //   cursor: "pointer",
              // }}
            >
              Export
            </button>
            <button
              onClick={() => {
                navigate("/review2024");
              }}
              className="export-button"
              // style={{
              //   margin: "20px auto",
              //   display: "block",
              //   padding: "10px 20px",
              //   borderRadius: "8px",
              //   border: "none",
              //   backgroundColor: "#007AFF",
              //   color: "white",
              //   cursor: "pointer",
              // }}
            >
              Change Your Answers
            </button>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default My2024;
