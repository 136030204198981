import axios from "axios";
import { cleanWithNewLines } from "../utils/parsing";
import { getAuth } from "firebase/auth";

const API_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:3001";

export const generateTitleFromContent = async (content, userId, noteId) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${API_URL}/generate-title`,
      {
        content: cleanWithNewLines(content),
        userId: userId,
        noteId: noteId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.data.title) {
      throw new Error("Failed to generate title");
    }

    return response.data.title;
  } catch (error) {
    console.error("Error generating title:", error);
    throw error;
  }
};
