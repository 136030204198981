import React, { useState, useEffect } from "react";
import "../styles/Modal.css";
import { X } from "lucide-react";

function GoalModal({ isOpen, onSaveGoal, handleSkip, setShowGoalModal }) {
  const [dayDescription, setDayDescription] = useState("");
  const [journalingGoal, setJournalingGoal] = useState("");
  const [desiredFeedback, setDesiredFeedback] = useState("");
  const handleSave = () => {
    if (dayDescription.trim() || journalingGoal.trim() || desiredFeedback.trim()) {
      const combinedGoal = [dayDescription, journalingGoal, desiredFeedback].filter((g) =>
        g.trim()
      );

      onSaveGoal(combinedGoal);
      setShowGoalModal(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-big">
        <div className="goal-instructions">
          <div className="goal-modal-header">
            <h3>Nice to meet you!</h3>
          </div>
          <p className="instructions-text">
            This helps Pearl to get to know you. You can change this later as your goals shift.
          </p>
          {/* <button onClick={onClose} className="icon-button">
            <X size={16} />
          </button> */}
        </div>
        <div className="">
          <textarea
            value={dayDescription}
            onChange={(e) => setDayDescription(e.target.value)}
            className="goal-textarea"
            placeholder="What does a typical day look like for you?"
            autoFocus
          />
          <div className="dashed-divider" />
          <textarea
            value={journalingGoal}
            onChange={(e) => setJournalingGoal(e.target.value)}
            className="goal-textarea"
            placeholder="How would you describe yourself?"
          />
          <div className="dashed-divider" />
          <textarea
            value={desiredFeedback}
            onChange={(e) => setDesiredFeedback(e.target.value)}
            className="goal-textarea"
            placeholder="What would you like to achieve through journaling?"
          />
          <div className="goal-actions">
            <button onClick={handleSkip} className="skip-button">
              Skip
            </button>
            <button
              onClick={handleSave}
              className="submit-button"
              disabled={!dayDescription.trim() && !journalingGoal.trim() && !desiredFeedback.trim()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoalModal;
