import axios from "axios";
import { getAuth } from "firebase/auth";
const API_URL = `${
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_URL : "http://localhost:3001"
}/analytics`;

export const trackUserAuth = async (user, eventName) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    await axios.post(
      `${API_URL}/track-auth`,
      {
        userId: user.uid,
        email: user.email,
        name: user.displayName || user.email.split("@")[0],
        eventName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Failed to track auth event:", error);
  }
};

export const trackEvent = async (event, properties) => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    await axios.post(
      `${API_URL}/track`,
      { event, properties },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Failed to track event:", error);
  }
};
