import React, { useState, useEffect } from "react";
import { MoreHorizontal, Heart, X, Upload } from "lucide-react";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "../styles/Modal.css";
import axios from "axios";
import ProfileModal from "./ProfileModal";
import FeedbackModal from "./FeedbackModal";

function SignOutMenu({ onSignOut, user, goal, setGoal, handleFileImport }) {
  const [showSignOutMenu, setShowSignOutMenu] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSignOutMenu && !event.target.closest(".menu-button-container")) {
        setShowSignOutMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSignOutMenu]);

  return (
    <>
      <div className="menu-button-container">
        <button onClick={() => setShowSignOutMenu(!showSignOutMenu)} className="icon-button">
          <MoreHorizontal size={16} />
          <span className="tooltip tooltip-bottom-left">Menu</span>
        </button>
        {showSignOutMenu && (
          <div className="menu-popup">
            <button
              onClick={() => {
                setShowProfileModal(true);
                setShowSignOutMenu(false);
              }}
              className="menu-item"
            >
              My Account
            </button>
            <button
              className="menu-item"
              onClick={() => document.getElementById("markdown-file-input").click()}
            >
              Import Files
            </button>
            <input
              id="markdown-file-input"
              type="file"
              accept=".md,.txt"
              multiple
              onChange={(e) => {
                if (e.target.files.length > 10) {
                  toast("Please select 10 or fewer files to upload at once.", {
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                  e.target.value = ""; // Reset the input
                  return;
                }
                handleFileImport(e);
              }}
              style={{ display: "none" }}
            />
            <button
              onClick={() => window.open("https://discord.gg/bvWwbC5dcH", "_blank")}
              className="menu-item"
            >
              Join our Discord
            </button>
            <button
              onClick={() => {
                setShowFeedbackModal(true);
                setShowSignOutMenu(false);
              }}
              className="menu-item"
            >
              Send us feedback &lt;3
            </button>
          </div>
        )}
      </div>
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        user={user}
      />
      <ProfileModal
        isOpen={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        user={user}
        goal={goal}
        setGoal={setGoal}
        handleSignOut={onSignOut}
      />
    </>
  );
}
export default SignOutMenu;
