import axios from "axios";
import { extractReflections } from "../utils/parsing";
import { getAuth } from "firebase/auth";
const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://journal-server-two.vercel.app/reflect"
    : "http://localhost:3001/reflect";

const generateReflection = async (content, userId, noteId, goal = null) => {
  try {
    const { cleanedText, reflections } = extractReflections(content);
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      API_URL,
      {
        content: cleanedText,
        reflections,
        userId,
        noteId,
        goal,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.question;
  } catch (error) {
    console.error("Error generating reflection:", error);
    return "";
  }
};

export default generateReflection;
